import React from 'react'
import styled from 'styled-components'
import IconButton from './icon-button'
import { SortType } from './types'
import FilterByMonth from './filter-by-month'
import { Dayjs } from 'dayjs'
interface Props {
  sortType: SortType
  filterDate?: Dayjs
  onSort(type: SortType): void
  onFilter(date: Dayjs): void
}

export default function Tabbar({
  sortType,
  filterDate,
  onSort,
  onFilter,
}: Props) {
  const handleSort = () => onSort(sortType === 'default' ? 'date' : 'default')

  return (
    <Container>
      <Title>干货</Title>
      <Filters>
        <IconButton name="sort" onClick={handleSort}>
          {sortType === 'date' ? '按时间' : '默认'}排序
        </IconButton>
        <FilterByMonth date={filterDate} onChange={onFilter} />
      </Filters>
    </Container>
  )
}

const Container = styled.div`
  background: white;
  padding: 12px 32px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c4c4c4;
`

const Title = styled.h2`
  font-size: 18px;
  line-height: 23px;
  color: #ff9234;
`

const Filters = styled.div`
  flex-direction: row;

  > * + * {
    margin-left: 36px;
  }
`
