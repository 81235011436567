import styled from 'styled-components'

const TwoColumns = styled.div`
  flex-direction: row;
  justify-content: center;

  > *:last-of-type {
    margin-left: 12px;
    width: 290px;
  }
`

export default TwoColumns
