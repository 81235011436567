import { useState, useEffect } from 'react'
import axios from 'axios'
import { Topic } from '../types'
import { topicMapper } from '../utils'

function fetchTopicsBy(keyword: string): Promise<Topic[]> {
  return axios
    .get(`/api/videos?keyword=${keyword}`)
    .then((resp) => resp.data)
    .then((data) => data.map(topicMapper))
}

export default function useTopicsBy(keyword: string) {
  const [topics, setTopics] = useState<Topic[]>([])

  useEffect(() => {
    if (!keyword) return
    fetchTopicsBy(keyword).then(setTopics)
  }, [keyword])

  return topics
}
