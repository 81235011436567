import React from 'react'
import styled from 'styled-components'
import Tag from './tag'
import { noop } from '../utils'

interface Props {
  source: string[]
  disabledTags?: string[]
  onChange?(tag: string): void
}

export default function Filter({
  source,
  disabledTags,
  onChange = noop,
}: Props) {
  return (
    <Container>
      <ScrollView>
        {source.map((t) => (
          <Tag key={t} disabled={disabledTags?.includes(t)} onClick={onChange}>
            {t}
          </Tag>
        ))}
      </ScrollView>
    </Container>
  )
}

const Container = styled.div`
  flex-direction: row;
  background: white;
  overflow-x: auto;
  overflow-y: hidden;
`

const ScrollView = styled.div`
  flex-direction: row;
  padding: 12px 24px;

  > * + * {
    margin-left: 8px;
  }
`
