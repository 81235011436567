import { Topic } from '../types'
import { useState, useEffect } from 'react'

export default function useTagsByTopics(topics: Topic[]) {
  const [tags, setTags] = useState<string[]>([])

  useEffect(() => {
    const tagSet = new Set<string>()
    topics.forEach((t) => t.tags.forEach((tag) => tagSet.add(tag)))
    setTags(Array.from(tagSet))
  }, [topics])

  return tags
}
