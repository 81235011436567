import React from 'react'
import styled from 'styled-components'
import PopularTopics from './popular-topics'
import Icon from './icon'
import SocialMedia from './social-media'
import usePopularTopics from '../hooks/usePopularTopics'
import { onMobile } from '../utils'
import Refresh from './refresh'

export default function SideBar() {
  const [popularTopics, refreshPopularTopics] = usePopularTopics()

  return (
    <Container>
      <Inner>
        <Related>
          <Title>
            <span>相关推荐</span>
            <Refresh color="black" onClick={refreshPopularTopics} />
          </Title>
          <div style={{ padding: 16 }}>
            <PopularTopics minimized source={popularTopics} />
          </div>
        </Related>

        <Ask href="mailto:wholelawyers@ailaw.ai">
          <Icon name="question" />
          <Prompt>
            <p>告诉我们你想问什么？</p>
            <span>我们将收集问题让专业律师回答您！</span>
          </Prompt>
        </Ask>

        <Links>
          <a href="tel:650-396-3985">Tel: 650-396-3985</a>
          <a href="mailto:business@ailaw.ai">
            Business Info: business@ailaw.ai
          </a>
          <a href="mailto:support@ailaw.ai">Technical Info: support@ailaw.ai</a>
          <p>160 East Tasman Drive, Suite 105</p>
          <p>San Jose, California 95134</p>
          <a href="/">Privacy Policy</a>
          <a href="/">Terms of Use</a>
        </Links>

        <div style={{ alignSelf: 'flex-start', marginTop: 36 }}>
          <SocialMedia />
        </div>
      </Inner>
    </Container>
  )
}

const Container = styled.div`
  margin-top: 12px;

  ${onMobile} {
    display: none;
  }
`

const Inner = styled.div`
  position: fixed;
  width: inherit;

  > * + * {
    margin-top: 12px;
  }
`

const Related = styled.div`
  background: white;
`

const Title = styled.div`
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  border-bottom: 1px solid #c4c4c4;

  > span {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #3a3f45;
  }
`

const Ask = styled.a`
  padding: 16px 24px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;

  > * + * {
    margin-left: 12px;
  }
`

const Prompt = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: black;

  > span {
    color: #c4c4c4;
    margin-top: 2px;
  }
`

const Links = styled.div`
  font-size: 10px;
  line-height: 13px;
  text-decoration-line: underline;

  color: #c4c4c4;

  > * + * {
    margin-top: 6px;
  }
`
