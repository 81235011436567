import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import IconButton from './icon-button'
import { touchable } from '../components/global-style'
import dayjs, { Dayjs } from 'dayjs'

interface Props {
  date?: Dayjs
  onChange(date: Dayjs): void
}

const currentYear = dayjs().year()

const months = Array(12)
  .fill(0)
  .map((_, i) => i + 1)

export default function FilterByMonth({ date, onChange }: Props) {
  const [expanded, setExpanded] = useState(false)

  const year = date?.year() ?? currentYear
  const month = date?.month() + 1

  const handleYearChange = (diff: number) => () => {
    const currentDate = date ?? dayjs()
    const newVal = year + diff

    if (newVal > currentYear || currentYear - newVal > 2) return
    onChange(currentDate.year(newVal))
  }

  const handleMonthChange = (current: number) => () => {
    const currentDate = date ?? dayjs()
    onChange(currentDate.month(current - 1))
  }

  return (
    <Container>
      <IconButton name="calendar" onClick={() => setExpanded(!expanded)}>
        只显示此月之后
      </IconButton>

      {expanded && (
        <Overlap>
          <Year>
            <ChangeYear onClick={handleYearChange(-1)}>←</ChangeYear>
            <span>{year}</span>
            <ChangeYear onClick={handleYearChange(1)}>→</ChangeYear>
          </Year>
          <List>
            {months.map((v) => (
              <Item
                key={v}
                className={v === month && 'active'}
                onClick={handleMonthChange(v)}
              >
                {v} 月
              </Item>
            ))}
          </List>
        </Overlap>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  font-size: 12px;
  color: #3a3f45;
  line-height: 16px;
`

const Year = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 2px;
  user-select: none;
`

const ChangeYear = styled.button`
  ${touchable}
  background: transparent;
  font-size: inherit;
  color: inherit;
`

const Overlap = styled.div`
  position: absolute;
  top: 100%;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

const List = styled.div`
  width: 140px;
  flex-direction: row;
  flex-wrap: wrap;
`

const Item = styled.div`
  ${touchable}

  width: 25%;
  height: 36px;
  align-items: center;
  justify-content: center;

  &.active {
    background: #ff9234;
    color: white;
    font-size: 1.1em;
  }
`
