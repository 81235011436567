import React from 'react'
import styled from 'styled-components'
import Icon from '../components/icon'
import { Lawyer } from '../types'
import { Link } from 'gatsby'
import { isPresent, onMobile, onTablet } from '../utils'

interface Props {
  source: Lawyer
}

export default function Attorney({ source }: Props) {
  return (
    <Info to={source.link} target="_blank">
      <Name>
        <span>{source.name}</span>
        <Icon name="confirmed" />
      </Name>
      {(isPresent(source.lawfirm) && <Lawfirm>{source.lawfirm}</Lawfirm>)}
    </Info>
  )
}

const Info = styled(Link)`
  font-size: 12px;
  line-height: 16px;
  color: #3a3f45;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${onMobile} {
    display: block;
  }

  > * + * {
    margin-left: 12px;
  }
`

const Name = styled.span`
  ${onTablet} {
    display: flex;
    align-items: center;
  }

  > * + * {
    margin-left: 4px;
  }
`

const Lawfirm = styled.span`
  &::before {
    content: '@';
  }
`
