import React from 'react'
import styled from 'styled-components'
import { noop } from '../utils'

interface Props {
  children: string
  disabled?: boolean
  onClick?(tag: string): void
}

export default function Tag({
  children,
  disabled = false,
  onClick = noop,
}: Props) {
  return (
    <Container
      className={disabled ? 'disabled' : ''}
      onClick={() => onClick(children)}
      style={{ cursor: onClick === noop ? 'unset' : 'pointer' }}
    >
      {children}
    </Container>
  )
}

const Container = styled.div`
  width: 98px;
  height: 24px;
  background: #ff9234;
  border-radius: 5px;
  text-align: center;
  line-height: 24px;
  color: #ffffff;
  font-size: 12px;
  overflow: hidden;

  &.disabled {
    background: #c4c4c4;
    color: white;
  }
`
