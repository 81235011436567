import React from 'react'
import styled from 'styled-components'
import Logo from './logo'
import SearchBar from './search-bar'
import TwoColumns from './two-columns'
import { Link } from 'gatsby'
import { onMobile, onTablet } from '../utils'
import Icon from './icon'

export default function Header() {
  return (
    <>
      <Outer>
        <Container>
          <Link to="/">
            <Icon name='blackTextLogo'/>
          </Link>
          <SearchBar size="small" />
        </Container>
        <div />
      </Outer>
      <Container className="mobile">
        <Link to="/">
          <Logo size="mini" />
        </Link>
        <SearchBar size="small" />
      </Container>
    </>
  )
}

const Outer = styled(TwoColumns)`
  background: white;
  z-index: 10;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 0;
  
  ${onMobile} {
    display: none;
  }
`

const Container = styled.div`
  background: white;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: sticky;
  
  ${onMobile} {
    z-index: 10;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  }

  > * + * {
    margin-left: 40px;

    ${onMobile} {
      margin-left: 10px;
    }
  }

  ${onTablet} {
    &.mobile {
      display: none;
    }
  }
`
