import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Tag from '../components/tag'
import Attorney from './attorney'
import { Topic } from '../types'
import { isPresent, mmddyyyyFormatter, onMobile } from '../utils'
import topicPathOf from '../topic-path'

interface Props {
  source: Topic[]
}

function usePage() {
  const [page, setPage] = useState(1)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const listener = () => {
      if (
        document.body.scrollHeight - window.scrollY - window.innerHeight >
        10
      ) {
        return
      }

      if (height > document.body.scrollHeight) {
        return
      }

      setHeight(document.body.scrollHeight)
      setPage(page + 1)
    }

    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [page, height])

  return page
}

export default function TopicList({ source }: Props) {
  const page = usePage()
  const topics = source.slice(0, 10 * page)

  return <Container onScroll={console.log}>{topics.map(renderItem)}</Container>
}

function renderItem(source: Topic) {
  const removeHtmlDesc = source.description.replace(/<[^>]+>/g, '')
  const desc = isPresent(removeHtmlDesc) ? removeHtmlDesc : ''

  return (
    <Item key={source.id}>
      <Head>
        <Title>
          <span>[{mmddyyyyFormatter(source.dateString)}]</span>
          <span> </span>
          <a href={topicPathOf(source)} target="_blank">
            <u>{source.title}</u>
          </a>
        </Title>
      </Head>

      <Body>
        {source.jumpLink && source.link && source.jumpLink !== 'nan' && source.link !== 'nan' ? (
          <Video
            v-if={source.jumpLink && source.link}
            href={source.jumpLink}
            target="_blank"
            dangerouslySetInnerHTML={{ __html: source.link }}
          />
        ) : (
          ''
        )}
        <div style={{ flex: 1 }}>
          <Lead>
            <Tag>{source.tags[0]}</Tag>
            {source.lawyer != null && <Attorney source={source.lawyer}/>}
          </Lead>
          <Trail>
            {desc}
          </Trail>
        </div>
      </Body>
    </Item>
  )
}

const Container = styled.div`
  border-top: 1px solid #c4c4c4;
  padding: 0 30px;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;

  & + & {
    border-top: 1px solid #c4c4c4;
  }

  > * + * {
    margin-top: 12px;
  }
`

const Head = styled.div`
  flex-direction: row;
  justify-content: space-between;
`

const Title = styled.div`
  flex: 1;
  font-size: 18px;
  line-height: 24px;
  color: #ff9234;
  display: block;
`

const Body = styled.div`
  flex-direction: row;

  > * + * {
    margin-left: 14px;
  }
`

const Video = styled.a`
  display: block;

  > iframe {
    width: 148px;
    height: 65px;
    pointer-events: none;

    ${onMobile} {
      width: 112px;
      height: 64px;
    }
  }
`

const Lead = styled.div`
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${onMobile} {
    > * + * {
      margin-top: 8px;
    }
  }
`

const Trail = styled.div`
  font-size: 10px;
  line-height: 13px;

  color: #c4c4c4;
  margin-top: 8px;
  height: 26px;

  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${onMobile} {
    display: none;
  }
`
