import React, { ReactNode } from 'react'
import Icon, { IconName } from '../components/icon'
import { noop, onMobile } from '../utils'
import styled from 'styled-components'
import { touchable } from '../components/global-style'

interface Props {
  name: IconName
  children: ReactNode
  onClick?(): void
}
export default function IconButton({ name, children, onClick }: Props) {
  return (
    <Container onClick={onClick}>
      <Icon name={name} />
      <Children>{children}</Children>
    </Container>
  )
}

const Container = styled.div`
  ${touchable}

  flex-direction: row;
  align-items: center;
`

const Children = styled.div`
  margin-left: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #c4c4c4;

  ${onMobile} {
    display: none;
  }
`
