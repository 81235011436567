import React, { useState } from 'react'
import GlobalStyle from '../components/global-style'
import Header from '../components/header'
import styled from 'styled-components'
import Tabbar from './tabbar'
import Background from '../components/background'
import Filter from '../components/filter'
import TopicList from './list'
import SideBar from '../components/side-bar'
import TwoColumns from '../components/two-columns'
import useTopicsBy from './useTopicsBy'
import useKeyword from '../hooks/useKeyword'
import useTagsByTopics from './useTagsByTopics'
import { SortType } from './types'
import { Topic } from '../types'
import { mapWith } from '../utils'
import dayjs, { Dayjs } from 'dayjs'

export default function TopicsPage() {
  const keyword = useKeyword()
  const [disabledTags, setDisabledTags] = useState<string[]>([])
  const [sortType, setSortType] = useState<SortType>('default')
  const [filterDate, setFilterDate] = useState<Dayjs>()

  const topics = useTopicsBy(keyword)
  const tags = useTagsByTopics(topics)

  const filterDisabledTags = (topics: Topic[]) =>
    topics.filter((t) => !t.tags.some((tag) => disabledTags.includes(tag)))
  const sortByDate = (topics: Topic[]) =>
    topics.sort((a, b) => b.timestamp - a.timestamp)
  const filterByMonth = (topics: Topic[]) =>
    topics.filter((t) => dayjs(t.timestamp).isAfter(filterDate))

  const mappers = [filterDisabledTags]
  if (sortType === 'date') mappers.push(sortByDate)
  if (filterDate != null) mappers.push(filterByMonth)

  const mappedTopics = mapWith(mappers)(topics)

  const onDisabledTagChange = (tag: string) => {
    if (disabledTags.includes(tag)) {
      setDisabledTags(disabledTags.filter((t) => t != tag))
      return
    }

    setDisabledTags([...disabledTags, tag])
  }

  return (
    <>
      <GlobalStyle />
      <Background />
      <Header />
      <TwoColumns>
        <Container>
          <Tabbar
            sortType={sortType}
            onSort={setSortType}
            filterDate={filterDate}
            onFilter={setFilterDate}
          />
          <Filter
            source={tags}
            disabledTags={disabledTags}
            onChange={onDisabledTagChange}
          />
          <TopicList source={mappedTopics} />
        </Container>
        <SideBar />
      </TwoColumns>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 700px;
  background: white;
`
